import React, { useState, useEffect, useRef } from 'react';
import AddToCartButton from '/Foundation/React/code/Components/Extranet/AddToCartButtonReact';
import { getRetailer } from '/Foundation/Common/code/Utils/Extranet/Utils';

const Search = ({ setShowSearch }) => {
  const inputReference = useRef(null);
  const [searchProducts, setSearchProducts] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [showExtranetAddToCart, setShowExtranetAddToCart] = useState(false);
  const searchTranslation = window._THULEDATA._MainNavigation.HeaderTranslations?.Search;
  const clearTranslation = window._THULEDATA._MainNavigation.HeaderTranslations?.Clear;
  const searchLandingPageURL = window._THULEDATA._MainNavigation.SearchLandingPageURL;
  const searchHitsMobile = window._THULEDATA._MainNavigation.SearchHitsMobile;
  const countryCode = window._THULEDATA.SitecoreLanguage;
  const productsText = window._THULEDATA._MainNavigation.SearchProductsText;
  const isExtranet = window._THULEDATA.isExtranet;

  const searchUrl =
    window._THULEDATA._MainNavigation.SearchApiUrl +
    `?query=${searchInput}&numberOfResults=${searchHitsMobile}&language=${countryCode}&imageSize=280`;

  useEffect(() => {
    const getSearchProducts = () => {
      setSearchProducts([]);
      const options = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      };
      fetch(searchUrl, { ...options, credentials: 'same-origin' })
        .then((response) => response.json())
        .then((data) => {
          if (data.length > 0) {
            setSearchProducts(data);
          }
        })
        .catch((e) => e);
    };

    if (searchInput.length >= 3) {
      const timer = setTimeout(() => {
        getSearchProducts();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [searchInput]);

  useEffect(() => {
    const retailer = getRetailer();
    if (retailer && retailer.B2BEnable && isExtranet) {
      setShowExtranetAddToCart(true);
    }
    else {
      setShowExtranetAddToCart(false);
    }
  }, []);

  const showSearchProduct = () =>
    searchProducts.map((item, i) => (
      <article key={i}>
        <a href={item.Link}>
          <div className={'search__product ' + (isExtranet ? 'extranet-search-product' : 'd-flex ')}>
            <div className="product__imageContainer">
              <img src={item.ProductImage} alt={item.Name} />
            </div>

            <div className="product__text mt-3 d-flex align-items-center">
              <div>
                {isExtranet && (
                  <div className="product__text">
                    <p className="paragraph--s mt-0 mb-5 text-color--gray60">{item.ErpOutdoor}</p>
                  </div>
                )}
                <p className="mb-2 heading5 text-color--gray80">{item.Name}</p>
                {item.Webtitle2 && <p className="paragraph-s text-color--gray60">{item.Webtitle2}</p>}
              </div>
            </div>
            {showExtranetAddToCart && (
              <AddToCartButton
                className="btn btn-sm btn-block thule-btn-shop add-to-cart-btn overflow-hidden"
                productData={item}
                productId={item.ErpOutdoor}
              />
            )}
          </div>
        </a>
      </article>
    ));

  const clearSearch = () => {
    setSearchInput('');
    setSearchProducts([]);
    inputReference.current.focus();
  };
  const onSearchBtnClick = (e) => {
    e.preventDefault();
    if (searchInput.length === 0) return;
    const searchUrl = searchLandingPageURL + '?search=' + searchInput;
    return (window.location = searchUrl);
  };

  return (
    <>
      <div className="search">
        <div className="d-flex align-items-center offset-lg-3 col-lg-6 searchInputContainer__wrapper">
          <div className="searchInputContainer bg-color--gray10">
            <i className="le-icon-search"></i>
            <form autoComplete="off" onSubmit={onSearchBtnClick}>
              <label htmlFor="mainNavigataion__searchInput" className="sr-only">
                Search input
              </label>
              <input
                ref={inputReference}
                className="p-3 w-100 mainNavigataion__searchInput paragraph text-color--black"
                type="text"
                placeholder={searchTranslation}
                name="mainNavigataion__searchInput"
                id="mainNavigataion__searchInput"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                autoFocus
              ></input>
            </form>
            {searchInput && (
              <div className="search__clear">
                <button className="btn p-0 text-color--gray80" onClick={() => clearSearch()}>
                  {clearTranslation}
                </button>
              </div>
            )}
          </div>
          <div className="ms-7">
            <button onClick={() => setShowSearch(false)} className="btn btn-close p-0">
              <span className="sr-only">Close search</span>
            </button>
          </div>
        </div>
        <div className="search__border"></div>
        {searchProducts.length > 0 && (
          <section className="search__result--product offset-xl-1 col-xl-10 col-xxl-6 offset-xxl-3 mt-8">
            <p className="paragraph--s text-color--gray60 mb-6">{productsText}</p>
            <div className="d-lg-flex">{showSearchProduct()}</div>
          </section>
        )}
        <div className="searchOverlay d-none d-lg-block"></div>
      </div>
    </>
  );
};
export default Search;
