export const calculateShipping = async (zipCode, products) => {
  return fetch('/api/sitecore/externalcheckout/getshippingoptions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      zipcode: zipCode,
      products: products,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};
