import * as actions from './types/actionTypes';

export const toggleCartNotification = (showCartNotification, quantityAddedToCart, productNameAddedToCart) => {
  return (dispatch) => {
    dispatch({
      type: actions.TOGGLE_CART_NOTIFICATION,
      payload: { showCartNotification, quantityAddedToCart, productNameAddedToCart },
    });
  };
};

export const setTotalCartQuantity = (totalCartQuantity) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_CART_TOTAL_QUANTITY,
      payload: { totalCartQuantity },
    });
  };
};
