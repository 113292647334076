import { Stream } from '@cloudflare/stream-react';
import './CloudflareVideo.less';
import React, { useEffect, useRef, useState } from 'react';

export const VideoRenderMode = {
    DEFAULT: 0,
    IMAGEGRID: 1,
};

const CloudflareVideo = ({ video, onRenderComplete }) => {
    const streamVideo = useRef();
    const isImageGrid = video.RenderMode === VideoRenderMode.IMAGEGRID;
    const [displayVideo, setDisplayVideo] = useState(!isImageGrid);
    const thumbNailUrl = `https://videodelivery.net/${video.CloudflareVideoId}/thumbnails/thumbnail.jpg?height=600&width=600`;

    useEffect(() => {
        if (onRenderComplete)
            onRenderComplete();
    }, []);

    const onThumbnailClick = () => {
        streamVideo.current.play();
        setTimeout(() => {
            setDisplayVideo(true);
        }, 350);
    }

    return <div className='cloudflareVideo'>
        {isImageGrid && <>
            <button onClick={onThumbnailClick} className={`btn p-0 playButton w-100 ${displayVideo ? "hide" : ""}`}>
                <div className='p-0 d-flex justify-content-center align-items-center h-100 w-100'><div className='playIcon'><i className="le-icon-play"></i></div>
                    <img className='w-100 object-fit-cover aspect-ratio-1x1' src={thumbNailUrl} alt='Video thumbnail' /></div>
            </button>
        </>}
        <div className='videoContainer'><Stream streamRef={streamVideo} controls={video.UseControls} src={video.CloudflareVideoId} autoplay={video.AutoPlay} loop={video.Loop} muted={video.Mute || video.AutoPlay} /></div>
    </div>
}

export default CloudflareVideo;