import { AdobeAnalyticsUtil } from './AdobeAnalyticsUtil';
export const Analytics_AddToCart = (() => {
  const addToCartButtonClass = '.tag-manager-add-to-cart-button';
  return {
    ApplyAddToCartButtonEvent: () => {
      try {
        document.querySelectorAll(addToCartButtonClass).forEach((item) => {
          item.addEventListener('click', () => {
            const trackingData = Analytics_AddToCart.GetTrackingDataFromElement(item);
            if (!trackingData) return;
            AdobeAnalyticsUtil.DataLayer.TrackEvent('cart_addition', {
              add_to_cart_variant: trackingData.source,
              products: trackingData.products,
            });
          });
        });
      } catch (error) {}
    },
    GetAnalyticsDataFromUpsellButton: (item) => {
      var accessory = item.closest('.accessory');
      let data = accessory.querySelector('.analyticsData')?.dataset;

      let product = {
        id: data.productid,
        name: data.thuleproductname,
        price: data.price,
        colour: data.colorspecification,
        original_price: data.originalprice,
        over_category: data.overcategory,
        category: data.category,
        subcategory: data.subcategory,
        quantity: '1',
      };

      return {
        products: [product],
        source: 'add_to_cart_upselling',
      };
    },
    GetTrackingDataFromSparePartObject: (sparePart) => {
      let product = {
        id: sparePart.ProductId,
        name: sparePart.Name,
        price: sparePart.Price,
        colour: sparePart.ColorSpecification,
        original_price: sparePart.Price,
        category: sparePart.Category,
        subcategory: sparePart.Subcategory,
        over_category: sparePart.OverCategory,
        quantity: '1',
      };
      return { products: [product], source: 'add_to_cart_sparepart' };
    },
    GetTrackingDataFromElement: (addToCartButtonElement) => {
      if (addToCartButtonElement.classList.contains('accessory-atc-button')) {
        const upsellData = Analytics_AddToCart.GetAnalyticsDataFromUpsellButton(addToCartButtonElement);
        return upsellData;
      }

      const productContainer = addToCartButtonElement.closest('.product-add-to-cart');
      const adobeAnalyticsData = productContainer?.querySelector('.analyticsData')?.dataset;

      if (!adobeAnalyticsData) return null;

      let product = {
        id: adobeAnalyticsData.productid,
        name: adobeAnalyticsData.thuleproductname,
        price: adobeAnalyticsData.price,
        colour: adobeAnalyticsData.colorspecification,
        original_price: adobeAnalyticsData.originalprice,
        category: adobeAnalyticsData.category,
        subcategory: adobeAnalyticsData.subcategory,
        over_category: adobeAnalyticsData.overcategory,
        quantity: '1',
      };

      let source;
      if (adobeAnalyticsData.isbundle === 'True' && addToCartButtonElement.classList.contains('sticky'))
        source = 'add_to_cart_bundle_sticky';
      else if (adobeAnalyticsData.isbundle === 'True') source = 'add_to_cart_bundle';
      else if (addToCartButtonElement.classList.contains('sticky')) source = 'add_to_cart_pdp_sticky';
      else if (addToCartButtonElement.classList.contains('dbg_cart_btn')) source = 'add_to_cart_DBG';
      else source = 'add_to_cart_pdp';

      return {
        products: [product],
        source,
      };
    },
  };
})();
