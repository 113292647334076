const PDP_Accordion = (function () {
  let self = {
    setTracking: () => {
      if (!google?.tagmanager) return;
      var accordionButtons = document.querySelectorAll('.pdp .accordion-section .accordion > button.toggleBtn');
      accordionButtons.forEach((accordionButton) => {
        accordionButton.addEventListener('click', (e) => {
          if (!e.target.classList.contains('collapsed')) {
            let category;
            let subCategory;
            if (dataLayer && dataLayer.length > 0) {
              category = dataLayer[0].category;
              subCategory = dataLayer[0].subcategory;
            }
            google.tagmanager.add({
              event: `accordionUsage`,
              selectedFilter: e.target.closest('.accordion')?.getAttribute('data-accordion'),
              category: category,
              subCategory: subCategory,
            });
          }
        });
      });
    },
    init: () => {
      self.setTracking();
    },
  };
  return self;
})();

export default PDP_Accordion;
