const Faqs = (() => {
  let self = {
    init: () => {
      const faqsButtons = document.querySelectorAll('.pdp__faqs .faqs__item .toggleBtn');
      faqsButtons.forEach((faqButton) => {
        faqButton.addEventListener('click', (e) => {
          if (!e.target.classList.contains('collapsed')) {
            let category;
            let subCategory;
            if (dataLayer && dataLayer.length > 0) {
              category = dataLayer[0].category;
              subCategory = dataLayer[0].subcategory;
            }
            google.tagmanager.add({
              event: `FAQusage`,
              selectedFilter: faqButton.getAttribute('data-id'),
              category: category,
              subCategory: subCategory,
            });
          }
        });
      });
    },
  };
  return self;
})();

export default Faqs;
