export const fetchPromise = (url, options) => {
  return new Promise((resolve, reject) => {
    fetch(url, { ...options, credentials: 'same-origin' })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
