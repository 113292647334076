import React, { useState, useEffect } from 'react';
import Category from './Category';
import Inspiration from '../inspiration';
import LinkSection from './LinkSection';
function MenuPrimary({
  trackNavigation,
  trackSecondaryNavigation,
  TopMenu,
  MyAccountLandingPageURL,
  MobileMyAccountText,
  DealerLocatorPageURL,
  MobileDealerLocatorText,
}) {
  const [selectedMenuSection, setSelectedMenuSection] = useState();
  const [selectedTopmenuItem, setSelectedTopmenuItem] = useState();
  const marketSelector = _THULEDATA._MainNavigation.MarketSelectorIcon;

  useEffect(() => {
    const mainContent = document.querySelector('.menuItem__mainContent.open');
    if (mainContent) {
      mainContent.scrollTop = 0;
    }
  }, [selectedMenuSection, selectedTopmenuItem]);

  const openLanguageSelector = () => {
    if (!_THULEDATA.isExtranet) {
      document.querySelector('button[data-bs-target="#marketSelector"]')?.click();
    }
  };

  const goBack = () => {
    if (selectedTopmenuItem) {
      if (selectedMenuSection) {
        setSelectedMenuSection(null);
      } else setSelectedTopmenuItem(null);
    }
  };
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  return (
    <nav className="mainNavigation" aria-label="Main navigation">
      <ul className={`menuPrimary ${selectedTopmenuItem ? 'mb-0' : 'my-6'}`}>
        {TopMenu.map(function (menuItem, keyIndex) {
          return (
            <li className="menuPrimary__menuItem" key={menuItem + '_' + keyIndex}>
              {selectedTopmenuItem && selectedTopmenuItem == menuItem && (
                <button onClick={goBack} className="btn--back menuItem__btn px-0 py-5 bg-color--gray10">
                  <div className="px-6 d-flex align-items-center">
                    <div>
                      <i className="fas fa-chevron-left"></i>
                    </div>
                    {!selectedMenuSection && (
                      <p className="m-0 ms-6 heading4 text--truncate">{selectedTopmenuItem.Title}</p>
                    )}
                    {selectedMenuSection && (
                      <p className="m-0 ms-6 heading4 text--truncate">
                        {selectedMenuSection.Name}
                        {selectedMenuSection.Title}
                      </p>
                    )}
                  </div>
                </button>
              )}
              <div
                className={`menuItem__mainContent ${
                  selectedTopmenuItem && selectedTopmenuItem == menuItem ? 'open' : ''
                }`}
              >
                {!selectedTopmenuItem && (
                  <>
                    {!menuItem.Link && (
                      <button
                        onClick={() => setSelectedTopmenuItem(menuItem)}
                        id={menuItem.Title}
                        className="btn menuItem__btn py-4 px-6 text--truncate"
                      >
                        <span className="menuItem__title heading4 d-block">{menuItem.Title}</span>
                        <div>
                          <i className="fas fa-chevron-right"></i>
                        </div>
                      </button>
                    )}

                    {menuItem.Link && (
                      <a
                        href={menuItem.Link}
                        id={menuItem.Title}
                        className={`btn menuItem__btn py-4 px-6 text--truncate`}
                      >
                        <span className="menuItem__title heading4 d-block">{menuItem.Title}</span>
                      </a>
                    )}
                  </>
                )}

                {selectedTopmenuItem && selectedTopmenuItem == menuItem && (
                  <>
                    <div
                      className="menuSection mb-6"
                      id={'menuSection_' + selectedTopmenuItem.Title}
                      hidden={selectedTopmenuItem == null}
                    >
                      {selectedTopmenuItem.ProductSections &&
                        selectedTopmenuItem.ProductSections.map(function (productSection, keyIndex) {
                          return (
                            <React.Fragment key={keyIndex}>
                              <div key={productSection + '_' + keyIndex}>
                                {productSection.Categories.map(function (category, keyIndex) {
                                  return (
                                    <Category
                                      setSelectedMenuSection={setSelectedMenuSection}
                                      selectedMenuSection={selectedMenuSection}
                                      key={keyIndex}
                                      menuSection={category}
                                      trackNavigation={trackNavigation}
                                      menuItem={selectedTopmenuItem}
                                    />
                                  );
                                })}
                              </div>
                            </React.Fragment>
                          );
                        })}

                      {selectedTopmenuItem.LinkSections &&
                        selectedTopmenuItem.LinkSections.map(function (linkSection, keyIndex) {
                          return (
                            <React.Fragment key={keyIndex}>
                              <LinkSection
                                key={keyIndex}
                                menuSection={linkSection}
                                trackNavigation={trackNavigation}
                                menuItem={selectedTopmenuItem}
                                setSelectedMenuSection={setSelectedMenuSection}
                                selectedMenuSection={selectedMenuSection}
                              />
                            </React.Fragment>
                          );
                        })}
                    </div>
                    {!selectedMenuSection && selectedTopmenuItem && (
                      <Inspiration selectedTopmenuItem={selectedTopmenuItem} />
                    )}
                  </>
                )}
              </div>
            </li>
          );
        })}
      </ul>
      {!selectedTopmenuItem && (
        <ul className="mainNavigation__links">
          {(_THULEDATA.isB2CEnabled || _THULEDATA.isExtranet) && (
            <li>
              <i className="le-icon-user me-5"></i>
              <a
                className="text-color--gray80 paragraph"
                href={MyAccountLandingPageURL}
                onClick={() => {
                  trackSecondaryNavigation('My Account', MyAccountLandingPageURL);
                }}
              >
                {MobileMyAccountText}
              </a>
            </li>
          )}

          {!_THULEDATA.isExtranet && (
            <li>
              <i className="le-icon-map-pin me-5"></i>
              <a className="text-color--gray80 paragraph" href={DealerLocatorPageURL}>
                {MobileDealerLocatorText}
              </a>
            </li>
          )}
          <li>
            <button
              onClick={openLanguageSelector}
              aria-describedby="market-desc"
              className="btn d-flex align-items-center"
            >
              <i className="le-icon-globe me-5"></i>
              <span className="text-color--gray80 paragraph">{marketSelector.Title}</span>
            </button>
          </li>
        </ul>
      )}
    </nav>
  );
}

export default MenuPrimary;
