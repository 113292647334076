import React, { memo } from 'react';

function Logo({ trackSecondaryNavigation }) {
  const url = '/' + _THULEDATA.SitecoreLanguage?.toLowerCase() + '/';
  const isCaseLogic = window._THULEDATA.SitecoreSiteName.toLowerCase() === 'caselogic';
  return (
    <a
      href={url}
      className="logotype-link lh-1"
      aria-label="Thule"
      onClick={() => {
        trackSecondaryNavigation('Logo', url);
      }}
    >
      <i className={isCaseLogic ? 'logotype-cl' : 'logotype'}></i>
    </a>
  );
}

export default memo(Logo);
