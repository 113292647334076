import React from 'react';
import '/Foundation/Styling/code/thule/global/media-queries.less';
import './ProgressBar.less';
const ProgressBar = ({ step, steps }) => {
  const getFillWidth = () => {
    return `${(step / steps) * 100}%`;
  };

  return (
    <div className={`common__progressBar mt-5 ${!step ? 'd-none' : ''}`}>
      <div className="line w-100"></div>
      <div className="line fill" style={{ width: getFillWidth() }}></div>
    </div>
  );
};

export default ProgressBar;
