export const LOCAL_STORAGE = {
  EXTRANET_USER: 'extranetUser',
  CART_IDENTIFIER: 'cartIdentifier',
  SHOW_RETAILER_MODAL: 'showRetailerModal',
  SEND_LOGIN_EVENT_GA: 'sendLoginEventGA',
};

export const COOKIE_STORAGE = {
  SELECTED_RETAILER_ID: 'selectedRetailerId',
  SELECTED_ADDRESS_ID: 'selectedAddressId',
  LANGUAGE: 'extranet#lang',
};

export const SORTING = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
  NONE: 'none',
};

export const CLAIM_TYPE_FILTERS = {
  ALL: 0,
  WARRANTY: 1,
  DELIVERY: 2
};

export const SORTTYPE = {
  ITEMNUMBER: 'item-number',
  PRODUCTNAME: 'product-name',
  COLOR: 'color',
  PRICE: 'price',
  REFERENCE_NUMBER: 'reference-number',
  ORDER_NUMBER: 'order-number',
  DATE: 'date',
  REQ_DELIVERY_DATE: 'req-delivery-date',
  STATUS: 'status',
  INVOICE_NUMBER: 'invoice-number',
  INVOICE_DATE: 'invoice-date',
  INVOICE_DUE_DATE: 'invoice-due-date',
  TOTAL_PRICE: 'total-price',
  CLAIM_OPENED_DATE: 'claim-opened-date',
  CLAIM_PURCHASE_DATE: 'claim-purchase-date',
  CLAIM_CASENUMBER: 'claim-casenumber',
  CLAIM_CATEGORYNAME: 'claim-categoryname',
  CLAIM_STATUS: 'claim-status',
};

export const DELIVERY_TERMS_WITHOUT_FREIGHT = ['FOC', 'FOF', 'FOO'];

export const PAYMENT_TERM = {
  INVOICE: 'Invoice',
  CREDIT_CARD: 'CreditCard',
};

export const ROLE_GUIDS = {
  EXTRANET_ADMIN: '0c932734-a2d0-4250-b14c-e687dacf8c1d',
  ADMIN: '9aa14885-1c69-42c0-841f-d5587f5efd17',
  PURCHASER: 'ee7ff03a-b097-4773-9639-803609cec335',
  DEALER_USER: '80c12991-06a1-4fe4-8e3a-0a8548ea3756',
  PENDING_APPROVAL: '8d57fe35-3595-428e-8815-a5abfa788274',
  INACTIVE: 'b3a1030d-505f-4878-818f-8e07c0e995ac',
  INVITED: '70bf358e-d2aa-4657-bc27-534c2dbed699',
  SALES_REP: 'f9ac75fd-fede-423a-83a7-28f89ae06f37',
  DEALER_HIDE_PRICES: 'b9c70a7d-6310-41de-b6f1-805b5aa8e283',
  CUSTOMER_ORDER_REGISTRATION_USER: '38e06058-9afd-49fd-a4c7-c3ae87949d58'
};

export const GENERAL_MESSAGE_TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
};

export const ERROR_CODES = {
  ABORT_DOMEXCEPTION: 20,
};

export const PRODUCT_TYPES = {
  ALL: 'All',
  MAIN_PRODUCT: 'Main product',
  ACCESSORY: 'Accessory',
  SPARE_PART: 'Spare part',
  FIT_KIT: 'Fit kit',
};

export const PRODUCT_STATUS = {
  ACTIVE: 'Active',
  DISCONTINUED: 'Discontinued',
};

//Used for google analytics
export const HEADER_NAV = {
  CART: 'Cart',
  MY_ACCOUNT: 'My account',
  SEARCH: 'Search',
  THULE_GROUP_LOGO: 'Thule Group logo',
};

export const ORDER_PLANNER = {
  ORDERSTATUS_SUBMITTED: 3,
};

export const CATALOGUE_BUILDER_TEMPLATE_TABS = {
  PRODUCT: 'Product',
  CATEGORY: 'Category',
  COLLATERAL: 'Collateral',
  SUBCATEGORY_ASSORTMENT: 'Subcategory assortment',
};

export const CATALOGUE_BUILDER_PAGE_LAYOUT = {
  PRODUCT: 1,
  PRODUCTPLUS: 2,
  CATEGORY: 3,
  COVER_START: 4,
  COVER_END: 5,
  COLLATERAL: 6,
  ACCESSORY: 7,
  SUBCATEGORY_ASSORTMENT: 8,
  PRODUCT_WITHOUT_COLORWAYS: 9,
  COMPARE: 10,
  EVERGREEN: 11,
};

export const CATALOGUE_BUILDER_VIEW_MODE = {
  THUMBNAIL: 'viewMode_thumbnail',
  MAIN: 'viewMode_previewMain',
  BASE_TEMPLATE: 'viewMode_baseTemplate',
};

export const INTERNAL_FIT_GUIDE_STATE = {
  UPDATED_PAST_SIX_MONTHS: 1,
  UPDATED_PAST_MONTH: 2,
};

export const CLASS_NAMES = {
  DISCOUNT_TWO: 'discount-two',
  TRANSACTION_REASON: 'transaction-reason',
  PRE_TEXT_LINES: 'pre-text-lines',
};

export const SHIP_WINDOW_STATUS = {
  OPEN: 0,
  LOCKED: 1,
  SUBMIT: 2,
};

export const PRESEASON_ORDER_STATUS = {
  ALL: 0,
  SAVED: 1, // Initial state
  SUBMITTED: 2, // Sent to M3.
};

export const SALES_PROGRAM_ORDER_STATUS = {
  SAVED: 0,
  SUBMITTED: 1,
};