import * as actions from '../actions/types/actionTypes';

const initialValues = {
  showCartNotification: false,
  quantityAddedToCart: 0,
  productNameAddedToCart: '',
  totalCartQuantity: 0,
};

const topNavigationReducer = (state = initialValues, action) => {
  switch (action.type) {
    case actions.TOGGLE_CART_NOTIFICATION: {
      return {
        ...state,
        showCartNotification: action.payload.showCartNotification,
        quantityAddedToCart: action.payload.quantityAddedToCart,
        productNameAddedToCart: action.payload.productNameAddedToCart,
      };
    }
    case actions.SET_CART_TOTAL_QUANTITY: {
      return {
        ...state,
        totalCartQuantity: action.payload.totalCartQuantity,
      };
    }
    default:
      break;
  }
  return state;
};

export default topNavigationReducer;
