export const objectShallowEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) return false;
  }

  return true;
};

export const isIOS = () => {
  let platform = navigator?.userAgent || navigator?.platform || 'unknown';

  return (
    /iPhone|iPod|iPad|iPad Simulator|iPhone Simulator|iPod Simulator/.test(platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const shouldShowCheckoutBtnSpinner = () => {
  return window._THULEDATA.SitecoreLanguage === 'pt-BR';
};

export const isMobile = () => {
  return window.innerWidth <= 767;
};
