import React from 'react';

function Category({ menuSection, setSelectedMenuSection, selectedMenuSection, trackNavigation, menuItem }) {
  return (
    <div className="category px-6">
      {!selectedMenuSection && (
        <>
          <button className="btn menuItem__btn py-4" onClick={() => setSelectedMenuSection(menuSection)}>
            <p className="paragraph--lead m-0 text-color--gray80 text--truncate">{menuSection.Name}</p>
            <div>
              <i className="fas fa-chevron-right"></i>
            </div>
          </button>
        </>
      )}

      {selectedMenuSection && selectedMenuSection == menuSection && (
        <div className="subcategory">
          <a
            className="paragraph--lead text-color--gray80 py-4 text--truncate"
            href={selectedMenuSection.Link}
            onClick={() =>
              trackNavigation(selectedMenuSection.Link, menuItem.EnglishTitle, selectedMenuSection.EnglishTitle, '')
            }
          >
            {selectedMenuSection.DiscoverAllText}
          </a>
          {menuSection.SubCategories &&
            menuSection.SubCategories.map(function (subcategory, keyIndex) {
              return (
                <a
                  onClick={() =>
                    trackNavigation(
                      subcategory.Link,
                      menuItem.EnglishTitle,
                      subcategory.EnglishTitle,
                      menuSection.EnglishTitle
                    )
                  }
                  key={keyIndex}
                  className="paragraph--lead text-color--gray80 py-4 text--truncate"
                  href={subcategory.Link}
                >
                  {subcategory.Name}
                </a>
              );
            })}
        </div>
      )}
    </div>
  );
}
export default Category;
