import { init } from '@sitecore/engage';

const getEngage = async (callback) => {
  if (window.Engage?.exposedFunctions) {
    callback(window.Engage.exposedFunctions);
    return;
  }

  if (!window.cdpVariables) {
    callback(null);
    return;
  }

  const settings = {
    clientKey: window.cdpVariables.cdpClientKey,
    targetURL: window.cdpVariables.cdpTargetURL,
    pointOfSale: window.cdpVariables.pointOfSale,
    cookieDomain: window.cdpVariables.cdpCookieDomain,
    cookieExpiryDays: 365,
    forceServerCookieMode: false,
    includeUTMParameters: true,
    webPersonalization: true /* boolean or object. See Settings object for all options. Default: false */,
  };

  // Expose Engage functions to the window object:
  window.Engage.exposedFunctions = await init(settings);
  callback(window.Engage.exposedFunctions);
};

export { getEngage };
