import CloudflareVideo from "./CloudflareVideo";
import React from "react";
import { createRoot } from "react-dom/client";
export const CloudflareVideoInitializer = (() => {
    return {
        init: (rootId, video) => {
            if (!rootId)
                return;

            const rootElement = document.getElementById(rootId);
            if (!rootElement)
                return;

            const root = createRoot(rootElement);
            root.render(<CloudflareVideo video={video} />);
        },
        initWithRootElement: (rootElement, onRenderComplete) => {
            if (!rootElement)
                return;
            const video = {
                CloudflareVideoId: rootElement.dataset.cloudflarevideoid,
                RenderMode: rootElement.dataset.rendermode === "IMAGEGRID" ? 1 : 0,
                UseControls: rootElement.dataset.usecontrols === "True",
                AutoPlay: rootElement.dataset.autoplay === "True"
            }

            const root = createRoot(rootElement);
            root.render(<CloudflareVideo video={video} onRenderComplete={onRenderComplete} />);
        }
    }
})();
window.cloudflareVideoInitializer = CloudflareVideoInitializer;