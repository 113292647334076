import React from 'react';
import Inspiration from '../inspiration';
function MenuContent({ menuItem, trackNavigation, onMenuMouseLeave }) {
  const isSupportLayout =
    !menuItem.HasProductSection && menuItem?.LinkSections?.filter((x) => x.SubLinks?.length > 0).length > 0;
  let showMore = false;
  let pls = [];
  let sls = [];

  const setupLinkSections = () => {
    if (isSupportLayout) {
      pls = menuItem?.LinkSections;
    } else {
      let imagecount = 0;
      menuItem?.LinkSections?.forEach(ls => {
        if (ls.ShowLinkImage && imagecount < 5) {
          pls.push(ls);
          imagecount++;
        } else {
          sls.push(ls);
        }
      });
    }
    showMore = !menuItem.HasProductSection && !isSupportLayout && sls && sls.length > 0;
  }

  setupLinkSections();

  return (
    <div
      className="menuContent"
      data-label={menuItem.EnglishTitle}
      onMouseLeave={(e) => {
        onMenuMouseLeave(e);
      }}
    >
      <div className="menuContent__block layout__content thule-container">
        <div
          className={`menuSections ${menuItem.PromotionField == null ? 'full' : ''} ${showMore ? 'showMore' : ''} ${isSupportLayout ? 'support' : ''
            }`}
        >
          {menuItem && menuItem.ProductSections && menuItem.HasProductSection && (
            <>
              {menuItem.ProductSections.map((productSection, keyIndex) => {
                return (
                  <React.Fragment key={keyIndex}>
                    {productSection.Categories.map((category, keyIndex) => {
                      if (category.SubCategories != null && category.SubCategories.length > 0) {
                        return (
                          <React.Fragment key={keyIndex}>
                            <div className="menuSection">
                              <a
                                className={`heading6 menuSection__categoryLink text-color--black ${_THULEDATA.isExtranet ? 'pe-none' : ''
                                  }`}
                                data-label={category.EnglishTitle}
                                onClick={(e) => {
                                  if (!_THULEDATA.isExtranet) {
                                    trackNavigation(category.Link, menuItem.EnglishTitle, category.EnglishTitle, '');
                                  }
                                }}
                                href={_THULEDATA.isExtranet ? '#' : category.Link}
                              >
                                {category.Name}
                              </a>
                              {category.SubCategories.map((subcategory, keyIndex) => {
                                return (
                                  <React.Fragment key={keyIndex}>
                                    <a
                                      className="mb-5 menuSection__link text-color--gray80"
                                      data-label={subcategory.EnglishTitle}
                                      onClick={() => {
                                        trackNavigation(
                                          subcategory.Link,
                                          menuItem.EnglishTitle,
                                          subcategory.EnglishTitle,
                                          category.EnglishTitle
                                        );
                                      }}
                                      href={subcategory.Link}
                                    >
                                      {subcategory.Name}
                                    </a>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        );
                      }
                    })}
                  </React.Fragment>
                );
              })}
            </>
          )}
          {pls && pls.map((linkSection, keyIndex) => {
            return (
              <React.Fragment key={keyIndex}>
                {
                  <div className="menuSection" key={keyIndex}>
                    {isSupportLayout && <p className="heading6 text-colors--black">{linkSection.Title}</p>}
                    {!isSupportLayout && (
                      <a
                        href={linkSection.Link}
                        data-label={linkSection.EnglishTitle}
                        className="menuSection__link heading6 text-color--gray80"
                        onClick={() => {
                          trackNavigation(
                            linkSection.Link,
                            menuItem.EnglishTitle,
                            linkSection.EnglishTitle,
                            '',
                            true
                          );
                        }}
                      >
                        <div className="menuSection__image mb-4">
                          <img loading="lazy" src={linkSection.ImageUrl} alt={linkSection.ImageAlt} />
                        </div>
                        <span>{linkSection.Title}</span>
                      </a>
                    )}
                    {linkSection.SubLinks &&
                      linkSection.SubLinks.map((subLink, keyIndex) => {
                        return (
                          <a
                            className="mb-5 text-color--gray80"
                            data-label={subLink.EnglishTitle}
                            href={subLink.Link}
                            key={keyIndex}
                            onClick={() => {
                              trackNavigation(
                                subLink.Link,
                                menuItem.EnglishTitle,
                                subLink.EnglishTitle,
                                linkSection.EnglishTitle
                              );
                            }}
                          >
                            {subLink.Title}
                          </a>
                        );
                      })}
                  </div>
                }
              </React.Fragment>
            );
          })}
          {showMore && (
            <div className="menuSection">
              <p className="heading6 mb-6">{menuItem?.MoreContentHeader}</p>
              {sls && sls.map((linkSection, keyIndex) => {
                return (
                  <a
                    key={keyIndex}
                    href={linkSection.Link}
                    data-label={linkSection.EnglishTitle}
                    className="menuSection__link text-color--gray80 mb-4"
                    onClick={() => {
                      trackNavigation(linkSection.Link, menuItem.EnglishTitle, linkSection.EnglishTitle, '');
                    }}
                  >
                    {linkSection.Title}
                  </a>
                );
              })}
            </div>
          )}
        </div>
        <Inspiration selectedTopmenuItem={menuItem} />
      </div>
    </div>
  );
}
export default MenuContent;
