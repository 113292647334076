export const AdobeAnalyticsUtil = (() => {
  return {
    DataLayer: (() => {
      return {
        TrackEvent: (eventName, data) => {
          if (!window._satellite?.track) return;
          window._satellite.track(eventName, data);
        },
      };
    })(),
  };
})();
