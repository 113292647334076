const pushToDataLayer = (dataLayerEvent, totalPrice, productsForMarketing) => {
  window.dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: dataLayerEvent,
    value: totalPrice,
    items: productsForMarketing,
  });
};

const productListIdArray = (products) =>
  products.map((item) => item?.dataset?.pimid ?? item.id).map((id) => ({ id, google_business_vertical: 'retail' }));

const productsAndPriceFromArray = (products) => {
  const productList = productListIdArray(products);

  const totalPrice = products
    .map((item) => {
      if (item?.parentElement?.querySelector('.ga-price')?.innerHTML) {
        return item?.parentElement?.querySelector('.ga-price')?.innerHTML;
      } else if (item?.price) {
        return item?.price;
      } else {
        return item?.dataset?.price;
      }
    })
    .map(parseFloat)
    .reduce((acc, val) => acc + val, 0);

  return { totalPrice, productList };
};

export const googleDynamicMarketingProductList = (dataLayerEvent) => {
  const products = document.querySelectorAll('.analytics-data');
  const productArray = Array.from(products);

  if (productArray.length <= 0) return;

  const items = productsAndPriceFromArray(productArray);
  return pushToDataLayer(dataLayerEvent, items.totalPrice, items.productList);
};

export const googleDynamicMarketingAddToCart = (data) => {
  let { product, products } = data;

  let productArray;
  if (product) productArray = Array.isArray(product) ? product : [product];
  else if (products && Array.isArray(products)) productArray = products;

  const items = productsAndPriceFromArray(productArray);
  return pushToDataLayer(`add_to_cart${data.type ? '_' + data.type : ''}`, items.totalPrice, items.productList);
};

export const googleDynamicMarketingOrderConfirm = (data) => {
  const { TotalAmount, Lines } = data;

  const productIds = Lines.map((item) => ({ id: item.ProductId }));

  const items = productListIdArray(productIds);

  return pushToDataLayer('purchase', TotalAmount, items);
};

export const googleDynamicMarketingSearch = () => {
  const arr = [];
  const activeLink = document.querySelector('.nav-link.active');
  const activeTabPane = document.querySelector('.tab-pane.active');

  if (arr.length <= 0 && activeTabPane) {
    arr.push(activeLink.innerHTML);
    const firstActiveTabPane = Array.from(activeTabPane.querySelectorAll('.analytics-data'));

    const items = productsAndPriceFromArray(firstActiveTabPane);
    pushToDataLayer('view_search_results', items.totalPrice, items.productList);
  }

  const navButtons = document.querySelectorAll('.nav-link');

  navButtons.forEach((btn) => {
    btn.addEventListener('click', () => {
      const tabPane = document.querySelector('.tab-pane.active');
      const products = tabPane?.querySelectorAll('.analytics-data');
      const isInArray = arr.some((btnText) => btnText === btn.innerHTML);

      if (!isInArray && products) {
        const tabPaneProduct = Array.from(products);

        if (tabPaneProduct.length > 0) {
          const items = productsAndPriceFromArray(tabPaneProduct);
          pushToDataLayer('view_search_results', items.totalPrice, items.productList);
        }
        arr.push(btn.innerHTML);
      }
    });
  });
};
