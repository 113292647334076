import React, { useEffect, useState } from 'react';
import { objectShallowEqual } from '../../../Utils/Utils';
import Input from './Input';
import ProgressBar from './ProgressBar/ProgressBar';

const ModalMenu = ({
  step,
  steps,
  selectionTitle,
  mainTitle,
  options,
  setSelectedItem,
  stepBack,
  close,
  label,
  placeholder,
  hideSearch,
  primaryButtonText,
  primaryButtonAction,
  secondaryButtonText,
  secondaryButtonAction,
  applySelectedItemStyling,
}) => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const html = document.querySelector('html');
  const body = document.querySelector('body');
  const showButtonSection =
    (primaryButtonText && primaryButtonAction) || (secondaryButtonText && secondaryButtonAction);

  const overflowHiddenClass = "overflow-hidden";
  useEffect(() => {
    setShowModal(true);
    return () => {
      document.body.classList.remove(overflowHiddenClass);
    };
  }, []);

  useEffect(() => {
    if (showModal) {
      body.classList.add(overflowHiddenClass);
    } else {
      body.classList.remove(overflowHiddenClass);
    }
  }, [showModal]);

  useEffect(() => {
    setSearchPhrase('');
  }, [step]);

  const onClose = () => {
    setShowModal(false);
    if (close) close();
  };

  const isFilterMatch = (value) => {
    if (!value) return false;

    if (!searchPhrase) return true;

    return value.toString().toLowerCase().indexOf(searchPhrase.toString().toLowerCase()) > -1;
  };

  return (
    <>
      <div className={`modalMenu right-slide-in-menu ${showModal ? 'wrapper-visible' : ''}`}>
        <div className="px-6 px-lg-9 mt-6">
          <div className="d-flex justify-content-between">
            {stepBack ? (
              <button
                className="btn p-0"
                onClick={(e) => {
                  stepBack();
                  e.preventDefault();
                }}
              >
                <span>
                  <i className="le-icon-arrow"></i>
                </span>
                <span className="sr-only">Back</span>
              </button>
            ) : (
              <div></div>
            )}

            <button
              className="btn p-0"
              onClick={(e) => {
                onClose();
                e.preventDefault();
              }}
            >
              <span>
                <i className="le-icon-x"></i>
              </span>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="py-5 d-flex justify-content-center flex-column align-items-center">
            <p className="paragraph--s text-color--gray60 mb-3">{selectionTitle}</p>
            <h1 className="heading3 text-center">{mainTitle}</h1>
            <ProgressBar step={step} steps={steps} />
          </div>
        </div>
        <div className={`menu-content-section ${selectedOption ? '' : 'px-6 px-lg-9'}`}>
          {!hideSearch && (
            <div>
              <Input
                trailingIcon="search"
                onChange={(e) => {
                  setSearchPhrase(e.target.value);
                }}
                id={`${mainTitle}_search`}
                type="text"
                label={label}
                placeholder={placeholder}
                value={searchPhrase}
              />
            </div>
          )}
          {options &&
            options.length > 0 &&
            options.map((option, index) => {
              return (
                <React.Fragment key={index}>
                  {isFilterMatch(option.Name) && (
                    <button
                      className={`btn modalMenu__item py-5 ${selectedOption && objectShallowEqual(option, selectedOption) ? 'selected px-6 px-lg-9' : !selectedOption ? '' : 'mx-6 mx-lg-9'}`}
                      onClick={(e) => {
                        setSelectedItem(option);
                        if (applySelectedItemStyling) setSelectedOption(option);
                        e.preventDefault();
                      }}
                    >
                      <div className="d-flex justify-content-between w-100 d-flex align-items-center">
                        <div className="text-start paragraph pe-3">{option.Name}</div>
                        <i
                          className={`le-icon-chevron rot-180 chevron ${applySelectedItemStyling && step === steps ? 'd-none' : ''}`}
                        ></i>
                        <i className="le-icon-check check d-none"></i>
                      </div>
                    </button>
                  )}
                </React.Fragment>
              );
            })}
        </div>
        {showButtonSection && (
          <div className="confirmation">
            {primaryButtonText && primaryButtonAction && (
              <button
                className="btn thule-btn-default"
                onClick={() => {
                  setShowModal(false);
                  primaryButtonAction();
                }}
                disabled={!selectedOption}
              >
                {primaryButtonText}
              </button>
            )}
            {secondaryButtonText && secondaryButtonAction && (
              <button
                className="btn thule-btn-secondary"
                onClick={() => {
                  setShowModal(false);
                  secondaryButtonAction();
                }}
              >
                {secondaryButtonText}
              </button>
            )}
          </div>
        )}
      </div>
      {showModal && <div className="modalOverlay" onClick={onClose}></div>}
    </>
  );
};

export default ModalMenu;
