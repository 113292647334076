export const cleanPhoneNumber = (phone) => {
  return phone.replace(/[\s\-]/g, '');
};

export const isVisaPayment = (order) => {
  return (
    order.CardType &&
    (order.CardType.toLowerCase() === 'ingenico_credit_card_visa' || order.CardType.toLowerCase() === 'braintree_visa')
  );
};

export const isMastercardPayment = (order) => {
  return (
    order.CardType &&
    (order.CardType.toLowerCase() === 'ingenico_credit_card_mastercard' ||
      order.CardType.toLowerCase() === 'braintree_master_card')
  );
};

export const isIngenicoPayPalPayment = (order) => {
  return order.CardType && order.CardType.toLowerCase() === 'ingenico_paypal';
};

export const isPayPalPayment = (order) => {
  return order.CardType && order.CardType.toLowerCase() === 'paypal_account';
};

export const isAffirmPayment = (order) => {
  return order.CardType && order.CardType.toLowerCase() === 'affirm';
};

export const isAfterpayPayment = (order) => {
  return order.CardType && order.CardType.toLowerCase().startsWith('afterpay');
};

export const isValidPassword = (password) => {
  return /^(?=.*[a-z])(?=.*[A-Z])(.{8,254})/.test(password);
};

export const isValidEmail = (email) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/.test(email);
};
