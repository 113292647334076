import { fetchPromise } from './apiHelper';
import { subscriptions } from '/Foundation/CDP/code/Utilities/cdpHelper';

const SITECORE_SEND_EMAIL_LIST_AND_PREFERENCES_URL = '/api/sitecore/sitecoresend/getemaillistsandpreferences';

const getSitecoreSendEmailListIdsAndPreferences = () => {
  const options = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  };
  return fetchPromise(SITECORE_SEND_EMAIL_LIST_AND_PREFERENCES_URL, options);
};

const cdpSubscribe = (source, sitecoreSendCustomFields) => {
  return cdpSubscription(source, 'subscribed', sitecoreSendCustomFields);
};

const cdpUnsubscribe = (source) => {
  return cdpSubscription(source, 'unsubscribed');
};

const cdpSubscription = (source, status, customFields) => {
  getSitecoreSendEmailListIdsAndPreferences().then((responseData) => {
    if (!responseData) {
      throw new Error('Could not get email list ids and preferences.');
    }

    let preferencesSendFields = [];
    responseData.Preferences.forEach((preference) => {
      preferencesSendFields.push({
        name: preference.ID,
        value: 'true',
        title: preference.Title
      });
    });

    const emailLists = responseData.MailingLists;
    const subscriptionsData = [];
    emailLists.forEach((list) => {
      subscriptionsData.push({
        mailingList: list.ID,
        mailingListName: list.Name,
        status,
        lastUpdated: new Date().toISOString(),
        source,
        language: window._THULEDATA.SitecoreLanguage,
        preferences: preferencesSendFields,
        customFields: customFields
      });
    });

    if (subscriptionsData.length > 0) {
      subscriptions(subscriptionsData);
    }
  });
};

export { cdpSubscribe, cdpUnsubscribe };
