import Splide from '@splidejs/splide';
import { getMercadoPaymentMessage } from '/Foundation/Common/code/Utils/PaymentUtils';
import { CloudflareVideoInitializer } from '/Foundation/Common/code/Views/Foundation/Common/CloudflareVideoInitializer';
import Lifestyle from './PDP/_Lifestyle';
import PDP_Accordion from './ProductDetail_Accordion';
// Not used in the file, but required for Accordion on
// ProductDetail pages(extranet).Do not remove.
import { Accordion } from 'bootstrap';
import Faqs from './PDP/_Faqs';
import ProductDetail_Tracking from './ProductDetail_Tracking';
import { calculateShipping } from '/Foundation/Common/code/Utils/CalculateShipping';
import { dynamicBundle } from './ProductDetail_DynamicBundle';
import { productWarning } from './ProductWarningModal';

let splide;
const pdpImages = document.querySelector('.pdp__images');
const imageCounter = pdpImages?.querySelector('.pdp__imageCounter');
let totalImages;
const progressFill = pdpImages?.querySelector('.progress-fill');

const initSplide = (element) => {
  if (!element)
    return;

  if (splide) splide.destroy();
  const splideSettings = {
    type: 'loop',
    start: 0,
    focus: 'center',
    autoWidth: true,
    arrows: true,
    pagination: false,
    clones: 1,
    mediaQuery: 'min',
    breakpoints: {
      992: {
        destroy: true,
      },
    },
  };

  splide = new Splide(element, splideSettings).mount();
  splide.on('moved', function (newIndex) {
    let currentImageNumber = newIndex + 1;
    if (currentImageNumber < 10) currentImageNumber = '0' + currentImageNumber;
    imageCounter.innerHTML = `${currentImageNumber}/${totalImages}`;
    progressFill.style.left = (100 / totalImages) * newIndex + '%';
  });
}
export const initMobileSlider = () => {

  let mobileSlider = document.querySelector('.pdp-splide');
  const cloudflareVideoRootElement = mobileSlider.querySelector(".imageGrid.imageGrid__cloudflare .cloudflareVideoContainer");

  if (cloudflareVideoRootElement && !splide) {
    CloudflareVideoInitializer.initWithRootElement(cloudflareVideoRootElement, () => {
      initSplide(mobileSlider);
    });
  } else {
    initSplide(mobileSlider);
  }
};

let productImages = (() => {
  const mainImages = pdpImages?.querySelector('.pdp__mainImages');
  let windowWidth = window.innerWidth;

  let self = {
    init: () => {
      initMobileSlider();
      self.setImageCounter();
      self.setImageClick();
      self.setupToggleShowMoreImages();
      self.setupResizeEvent();
      self.setupImageGrid();
      self.setupInstructionMovies();
    },
    setupToggleShowMoreImages: () => {
      const toggleImageShowMoreBtn = document.getElementById('btn_toggleImageShowMore');
      if (toggleImageShowMoreBtn) {
        if (totalImages > 6) {
          toggleImageShowMoreBtn.classList.remove('d-none');
        }
        toggleImageShowMoreBtn.addEventListener('click', () => {
          const imgContainer = document.querySelector('.pdp__mainImages');
          if (imgContainer) {
            if (imgContainer.classList.contains('showMore')) {
              imgContainer.classList.remove('showMore');
            } else imgContainer.classList.add('showMore');
          }
          //if (stickyBuyArea) stickyBuyArea.setBuyAreaPosition();
        });
      }
    },

    setImageClick: () => {
      const zoomImageDiv = pdpImages.querySelector('.pdp__zoomMobile');
      document.querySelector('.zoomCloseMobile').onclick = (e) => {
        zoomImageDiv.classList.add('d-none');
        document.body.classList.remove('overflow-hidden');
      };
      document.querySelector('.zoomClose').onclick = (e) => {
        mainImages.classList.remove('pdp__zoom');
        document.body.classList.remove('overflow-hidden');
      };
      const mainImageItems = [...pdpImages.querySelectorAll('.pdp__mainImage:not(.yt__thumbnail)')];
      mainImageItems.forEach((image) => {
        image.addEventListener('click', () => {
          if (window.innerWidth > 991) {
            document.querySelector('.pdp__mainImages').classList.add('pdp__zoom');
            document.body.classList.add('overflow-hidden');
            document.querySelectorAll('.pdp__mainImage')[mainImageItems.indexOf(image) || 0].scrollIntoView();
            return;
          }
          let zoomImage = document.getElementById('imgZoomMobile');
          if (!zoomImage) {
            let img = document.createElement('img');
            img.id = 'imgZoomMobile';
            document.querySelector('.imgWrapper').append(img);
            zoomImage = img;
          }
          zoomImageDiv.classList.remove('d-none');
          zoomImage.onload = (e) => {
            setTimeout(() => {
              if (zoomImageDiv.scrollWidth > 0 && zoomImageDiv.scrollHeight > 0) {
                zoomImageDiv.scrollLeft = zoomImageDiv.scrollWidth / 2 - windowWidth / 2;
                zoomImageDiv.scrollTop = zoomImageDiv.scrollHeight / 2 - window.innerHeight / 2;
              }
              document.body.classList.add('overflow-hidden');
            }, 1);
          };
          const imgSrc = image.parentElement.querySelector('picture .pdp__mainImage');
          zoomImage.src = imgSrc?.src;
          zoomImage.alt = image.alt;
          zoomImage.onclick = () => {
            zoomImageDiv.classList.add('d-none');
            document.body.classList.remove('overflow-hidden');
          };
        });
      });
    },

    setImageCounter: (currentImageNumber) => {
      if (imageCounter) {
        if (!currentImageNumber) currentImageNumber = 1;
        totalImages = pdpImages.querySelectorAll(
          '.pdp__mainImageWrapper:not(.d-none):not(.splide__slide--clone)'
        ).length;
        progressFill.style.width = 100 / totalImages + '%';
        progressFill.style.left = (100 / totalImages) * (currentImageNumber - 1) + '%';

        if (currentImageNumber < 10) currentImageNumber = '0' + currentImageNumber;
        if (totalImages < 10) totalImages = '0' + totalImages;

        imageCounter.innerHTML = `${currentImageNumber}/${totalImages}`;
        if (totalImages > 0) {
          imageCounter.classList.remove('d-none');
        }
      }
    },
    setupResizeEvent: () => {
      window.addEventListener(
        'resize',
        debounce(() => {
          if (windowWidth !== window.innerWidth) {
            self.setImageCounter();
            initMobileSlider();
            windowWidth = window.innerWidth;
          }
        })
      );
    },

    setupImageGrid: () => {
      const imageGrid = document.querySelector('.pdp__mainImages .imageGrid:not(.splide__slide--clone)');
      if (!imageGrid) return;

      imageGrid.querySelector('#ytPlayBtn')?.addEventListener('click', () => {
        let iframe = document.querySelector('#imageGridModal iframe');
        if (!iframe) return;
        iframe.src = iframe.src;
      });
    },

    setupInstructionMovies: () => {
      const instructionMovies = document.querySelectorAll('.instruction-yt-button');
      const instructionMovieModal = document.querySelector('#instructionVideoModal');
      const iframe = document.querySelector('#instructionVideoModal iframe');

      instructionMovies.forEach((element) => {
        element.addEventListener('click', () => {
          if (!iframe) return;
          iframe.src = element.dataset.src;
        });
      });

      instructionMovieModal?.addEventListener('hide.bs.modal', () => {
        if (!iframe) return;
        iframe.src = '';
      });
    },
  };

  return self;
})();

let hatchClearanceOverlay = (function () {
  let self = {
    init: function () {
      self.hideHatchMenuBtns = document.querySelectorAll('.hide-hatch-overlay');
      self.hatchMenu = document.querySelector('.hatch-clearance-menu');
      self.backdrop = document.querySelector('.hatch-left-slide-in-menu-backdrop');
      self.link = document.querySelector('.hatch-clearance a');
      self.closeHatchBtn = document.querySelector('.hide-hatch-overlay');

      self.link.addEventListener('click', (e) => {
        e.preventDefault();
        self.openHatchClearanceOverlay();
      });

      self.backdrop.addEventListener('click', function () {
        self.closeHatchClearanceOverlay();
      });

      self.closeHatchBtn.addEventListener('click', function () {
        self.closeHatchClearanceOverlay();
      });
    },
    openHatchClearanceOverlay: function () {
      self.hatchMenu.classList.remove('wrapper-hidden');
      self.backdrop.classList.add('active');
      document.body.classList.add('overflow-hidden');
    },
    closeHatchClearanceOverlay: function () {
      self.hatchMenu.classList.add('wrapper-hidden');
      self.backdrop.classList.remove('active');
      document.body.classList.remove('overflow-hidden');
    },
  };
  return self;
})();

let sizeGuideOverlay = (function () {
  let self = {
    init: function () {
      self.hideSizeGuideMenuBtns = document.querySelectorAll('.hide-size-guide-overlay');
      self.sizeGuideMenu = document.querySelector('.size-guide-menu');
      self.backdrop = document.querySelector('.size-guide-left-slide-in-menu-backdrop');
      self.link = document.querySelector('a.size-guide-link');
      self.closeSizeGuideBtn = document.querySelector('.size-guide-button');

      if (self.link) {
        self.link.addEventListener('click', (e) => {
          e.preventDefault();
          self.openSizeGuideOverlay();
        });
      }
      self.backdrop.addEventListener('click', function () {
        self.closeSizeGuideOverlay();
      });

      self.hideSizeGuideMenuBtns.forEach((btn) => {
        btn.addEventListener('click', function () {
          self.closeSizeGuideOverlay();
        });
      });
    },
    openSizeGuideOverlay: function () {
      self.sizeGuideMenu.classList.remove('wrapper-hidden');
      self.backdrop.classList.add('active');
      self.closeSizeGuideBtn.classList.remove('d-none');
      document.body.classList.add('overflow-hidden');
    },
    closeSizeGuideOverlay: function () {
      self.sizeGuideMenu.classList.add('wrapper-hidden');
      self.closeSizeGuideBtn.classList.add('d-none');
      self.backdrop.classList.remove('active');
      document.body.classList.remove('overflow-hidden');
    },
  };
  return self;
})();

let stickyAddToCart = (function () {
  let self = {
    isInViewport: (el, topOffset) => {
      const rect = el.getBoundingClientRect();
      return rect.top >= topOffset && rect.top <= document.documentElement.clientHeight;
    },
    init: () => {
      const addToCartBtn = document.querySelector(
        '.product-detail__buy-area .product-add-to-cart .thule-btn-shop:not(.roof-rack-btn)'
      );
      const addToCartBtnPreview = document.querySelector('.thule-btn-shop-preview');

      if (addToCartBtn && addToCartBtnPreview) {
        window.addEventListener('scroll', (event) => {
          const headerStyle = window.getComputedStyle(document.getElementById('thule-header'));
          let topOffset = 0;
          if (headerStyle.position === 'fixed' && headerStyle.height) {
            topOffset = parseInt(headerStyle.height);
          }
          const container = addToCartBtn.parentElement;
          if (!self.isInViewport(container, topOffset) && !addToCartBtn.classList.contains('sticky')) {
            addToCartBtn.classList.add('sticky');
            addToCartBtnPreview.classList.add('d-lg-flex');
          } else if (self.isInViewport(container, topOffset)) {
            addToCartBtn.classList.remove('sticky');
            addToCartBtnPreview.classList.remove('d-lg-flex');
          }
        });
      }
    },
  };
  return self;
})();

let bundleProductFlyout = (function () {
  let flyout = document.querySelector('.bundle-flyout');
  let backdrop = document.querySelector('.bundle-backdrop.backdrop');
  let self = {
    init: function () {
      if (flyout) {
        document.getElementById('btnBundleModal').addEventListener('click', function () {
          self.show();
        });

        backdrop.addEventListener('click', function () {
          self.hide();
        });

        flyout.querySelector('.close-btn').addEventListener('click', function () {
          self.hide();
        });

        flyout.querySelectorAll('.tag-manager-add-to-cart-button')?.forEach((btn) => {
          btn.addEventListener('click', () => {
            self.hide();
          });
        });
      }
    },

    show: function () {
      flyout.classList.add('wrapper-visible');
      backdrop.classList.add('active');
      document.querySelector('html').classList.add('no-scroll');
      document.querySelector('body').classList.add('no-scroll');
    },

    hide: function () {
      backdrop.classList.remove('active');
      flyout.classList.remove('wrapper-visible');
      document.querySelector('html').classList.remove('no-scroll');
      document.querySelector('body').classList.remove('no-scroll');
    },
  };
  return self;
})();

let reviews = (function () {
  let self = {
    init: function () {
      const reviewsLink = document.getElementById('pdp__reviewsLink');
      if (!reviewsLink) return;
      const reviewsAccordionButton = document.querySelector('#pdp__reviews .toggleBtn');
      if (!reviewsAccordionButton) return;

      reviewsLink.addEventListener('click', () => {
        if (reviewsAccordionButton.getAttribute('aria-expanded') === 'false') reviewsAccordionButton.click();
      });
    },
  };
  return self;
})();

const debounce = (func) => {
  var timer;
  return function (event) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, 100, event);
  };
};

const setDescriptionVisibility = (lineHeight, textElement, btnElement, maxLinesToShow) => {
  if (textElement && btnElement) {
    const textSpan = textElement.querySelector('span');
    const showButton = textSpan && textSpan.getBoundingClientRect()?.height > lineHeight * maxLinesToShow;
    if (showButton) {
      textElement.parentElement.classList.remove('readmore');
      btnElement.classList.remove('d-none');
    } else {
      textElement.parentElement.classList.add('readmore');
      btnElement.classList.add('d-none');
    }
  }
};

const setDescriptionReadMore = () => {
  const descriptionContentElement = document.querySelector('.descriptionContent');
  const readMoreBtn = document.querySelector('.toggleMoreContent');
  if (descriptionContentElement && readMoreBtn) {
    readMoreBtn.onclick = (e) => {
      descriptionContentElement.parentElement.classList.add('readmore');
    };
    setDescriptionVisibility(24, descriptionContentElement, readMoreBtn, 3);
  }
};

const mercado = (function () {
  let self = {
    init: function () {
      let mercadoPlacement = document.querySelector('.mercado-messaging');
      if (mercadoPlacement) {
        const mercadoBaseURL = document.querySelector('#mercadoBaseURL')?.value;
        const mercadoAPIKey = document.querySelector('#mercadoAPIKey')?.value;
        const amount = document.querySelector('.product-detail__buy-area').dataset.productprice;
        getMercadoPaymentMessage(mercadoBaseURL, mercadoAPIKey, amount);
      }
    },
  };
  return self;
})();

const shippingCalculator = (function () {
  const shippingCalcElem = document.querySelector('.shipping_calculator');
  const shippingCalcInput = shippingCalcElem?.querySelector('input');
  const shippingCalcBtn = shippingCalcElem?.querySelector('#shippingCalcButton');
  const btnText = shippingCalcBtn?.querySelector('.btn-text');
  const spinner = shippingCalcBtn?.querySelector('.spinner');

  let self = {
    init: function () {
      if (shippingCalcElem) {
        shippingCalcBtn.addEventListener('click', () => {
          btnText.classList.add('d-none');
          spinner.classList.remove('d-none');
          this.getShippingMethods();
        });

        shippingCalcInput.addEventListener('input', () => {
          const errorMessage = shippingCalcElem.querySelector('.error-message');
          if (!errorMessage.classList.contains('d-none')) {
            errorMessage.classList.add('d-none');
            shippingCalcElem.querySelector('.thule-input').classList.remove('error');
            shippingCalcElem.querySelector('.trailing-icon').classList.add('d-none');
          }
        });
      }
    },

    getShippingMethods: async function () {
      const resultsContainer = document.querySelector('.shipping-options-result');
      resultsContainer.innerHTML = '';
      if (!resultsContainer.classList.contains('d-none')) resultsContainer.classList.add('d-none');

      const zipCode = shippingCalcInput.value;
      const id = document.getElementById('productBuyArea').dataset.datasulid;
      let products = [{ qty: 1, sku: id }];

      const result = await calculateShipping(zipCode, products);
      btnText.classList.remove('d-none');
      spinner.classList.add('d-none');

      if (result?.ShippingMethods?.length === 0 || !result || !result?.ShippingMethods) {
        const errorMessage = shippingCalcElem.querySelector('.error-message');
        errorMessage.classList.remove('d-none');
        shippingCalcElem.querySelector('.thule-input').classList.add('error');
        shippingCalcElem.querySelector('.trailing-icon').classList.remove('d-none');
      } else {
        result.ShippingMethods.forEach((x) => {
          resultsContainer.innerHTML += `<div class="shipping-option"><span class="heading6 mb-3">${x.FormattedPrice}</span><p class="paragraph--s text-color--gray80">${x.Name}</p></div></div>`;
        });
        resultsContainer.classList.remove('d-none');
      }
    },
  };
  return self;
})();

const flowbox = (function () {
  let self = {
    init: function () {
      //Flowbox should only be visible on PDP if at least 6 posts are connected to the product
      const flowboxContainer = document.querySelector('#js-flowbox-flow');
      const ugcSection = document.querySelector('.ugc-section');
      if (ugcSection && flowboxContainer) {
        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            if (document.querySelector('.bzfy-t-feed-tile-grid')) {
              const posts = flowboxContainer.querySelectorAll('.bzfy-o-layout__item');
              if (posts?.length >= 6) {
                ugcSection.classList.remove('hide-flowbox');
              } else {
                window.flowbox('destroy', {
                  container: '#js-flowbox-flow',
                });
                ugcSection.classList.add('d-none');
              }
            }
          }
        });
        resizeObserver.observe(flowboxContainer);
      }
    },
  };
  return self;
})();

setDescriptionReadMore();
if (document.querySelector('.pdp__images')) {
  productImages.init();
}

let sizeGuide = document.querySelector('.size-guide');
if (sizeGuide) sizeGuideOverlay.init();

let hatchLink = document.querySelector('.hatch-clearance');
if (hatchLink) hatchClearanceOverlay.init();
stickyAddToCart.init();

let bpmElement = document.querySelector('.bundle-flyout');
if (bpmElement) bundleProductFlyout.init();

document.addEventListener('DOMContentLoaded', function () {
  ProductDetail_Tracking?.init();
  let productWarningElem = document.querySelector('.product-warning-flyout');
  if (productWarningElem) productWarning.init();

  Lifestyle?.init();
  PDP_Accordion?.init();
  Faqs?.init();
  reviews.init();
  mercado.init();
  dynamicBundle.init();
  flowbox.init();

  const shippingCalcElem = document.querySelector('.shipping_calculator');
  if (shippingCalcElem) shippingCalculator.init();
});
