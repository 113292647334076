const Lifestyle = (() => {
  let self = {
    init: () => {
      const ytPlayButtons = document.querySelectorAll('.pdp__lifestyle .pdp__lifestyleItem .ytPlayBtn');

      ytPlayButtons.forEach((ytPlayButton) => {
        ytPlayButton.addEventListener('click', () => {
          const lifestyleItem = ytPlayButton.closest('.pdp__lifestyleItem');

          let iframe = lifestyleItem.querySelector('iframe');
          if (!iframe) return;
          iframe.src = iframe.getAttribute('data-src').replace('autoplay=0', 'autoplay=1');
          iframe.classList.remove('d-none');
          ytPlayButton.classList.add('d-none');
          lifestyleItem.querySelector('.yt__thumbnail').classList.add('d-none');
        });
      });
    },
  };

  return self;
})();

export default Lifestyle;
