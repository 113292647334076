import React from 'react';

function LinkSection({ menuSection, trackNavigation, menuItem, setSelectedMenuSection, selectedMenuSection }) {
  const isSupportLayout =
    !menuItem.HasProductSection && menuItem?.LinkSections?.filter((x) => x.SubLinks?.length > 0).length > 0;
  return (
    <div className="px-6">
      {isSupportLayout && !selectedMenuSection && (
        <>
          <button className="btn menuItem__btn py-4" onClick={() => setSelectedMenuSection(menuSection)}>
            <p className="paragraph--lead m-0 text-color--gray80">{menuSection.Title}</p>
            <div>
              <i className="fas fa-chevron-right"></i>
            </div>
          </button>
        </>
      )}

      {!menuSection.SubLinks ||
        (menuSection.SubLinks.length === 0 && (
          <a
            onClick={() => trackNavigation(menuSection.Link, menuItem.EnglishTitle, menuSection.EnglishTitle, '')}
            className="paragraph--lead text-color--gray80 py-4"
            data-label={menuSection.EnglishTitle}
            href={menuSection.Link}
            key={menuSection}
          >
            {menuSection.Title}
          </a>
        ))}
      {selectedMenuSection &&
        selectedMenuSection == menuSection &&
        menuSection.SubLinks &&
        menuSection.SubLinks.map(function (subLink, keyIndex) {
          return (
            <a
              onClick={() =>
                trackNavigation(subLink.Link, menuItem.EnglishTitle, subLink.EnglishTitle, menuSection.EnglishTitle)
              }
              className="paragraph--lead text-color--gray80 py-4"
              data-label={subLink.EnglishTitle}
              href={subLink.Link}
              key={keyIndex}
            >
              {subLink.Title}
            </a>
          );
        })}
    </div>
  );
}
export default LinkSection;
