import React from 'react';

function Inspiration({ selectedTopmenuItem }) {
  const trackInspiration = (labelName, parentLabel, url, imageSection) => {
    var values = {
      event: 'navigationPromotion',
      headerSection: selectedTopmenuItem.EnglishTitle,
      parentLabel: parentLabel,
      labelName: labelName,
      clickedurl: window.location.host + url,
      imageSection: imageSection,
    };

    google.tagmanager.add(values);
  };

  return (
    <>
      {selectedTopmenuItem.PromotionField && (
        <div className="inspiration">
          <p
            className="heading6 inspiration__header mb-6"
            data-label={selectedTopmenuItem.PromotionField.EnglishInfoTextHeaderField}
          >
            {selectedTopmenuItem.PromotionField.InfoTextHeaderField}
          </p>

          <div className="inpiration__links">
            {selectedTopmenuItem.PromotionField.LinksMultiselectField.map(function (link, keyIndex) {
              return (
                <a
                  data-label={link.EnglishTitle}
                  onClick={(e) => {
                    trackInspiration(
                      link.EnglishTitle,
                      selectedTopmenuItem.PromotionField.EnglishInfoTextHeaderField,
                      link.Link,
                      'No'
                    );
                  }}
                  className="mb-5 paragraph--s text-color--gray80"
                  href={link.Link}
                  key={`inspiration__links${keyIndex}`}
                >
                  {link.DisplayName}
                </a>
              );
            })}
          </div>
          <div className="inspiration__promotion">
            {selectedTopmenuItem.PromotionField.ImageURL && (
              <a
                onClick={() => {
                  trackInspiration(
                    selectedTopmenuItem.PromotionField.DisplayName,
                    selectedTopmenuItem.PromotionField.EnglishInfoTextHeaderField,
                    selectedTopmenuItem.PromotionField.PromotionAreaLinkURL,
                    'Yes'
                  );
                }}
                href={selectedTopmenuItem.PromotionField.PromotionAreaLinkURL}
              >
                <img
                  src={selectedTopmenuItem.PromotionField.ImageURL}
                  alt={selectedTopmenuItem.PromotionField.ImageAltText}
                />
                <div className="tintOverlay">
                  <p className="heading5 mb-0">{selectedTopmenuItem.PromotionField.Header}</p>
                </div>
              </a>
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default Inspiration;
