import { Analytics_AddToCart } from '/Foundation/Common/code/Views/Foundation/Common/AdobeAnalytics/Analytics_AddToCart';
import { AdobeAnalyticsUtil } from '/Foundation/Common/code/Views/Foundation/Common/AdobeAnalytics/AdobeAnalyticsUtil';

const ProductDetail_Tracking = (() => {
  const userManualLinkClass = '.tag-manager-user-manual';
  const setupDownloadTracking = () => {
    document.querySelectorAll(userManualLinkClass).forEach((item) => {
      item.addEventListener('click', (e) => {
        const link = e.currentTarget;
        var values = {
          download_filename: link.dataset.name + '.' + link.dataset.assettype,
          download_source: 'instructions',
          download_fileurl: link.getAttribute('href'),
        };
        AdobeAnalyticsUtil.DataLayer.TrackEvent('download', values);
      });
    });
  };

  let self = {
    init: () => {
      if (!document.querySelector('.pdp') || !window._satellite) return;
      setupDownloadTracking();
      Analytics_AddToCart?.ApplyAddToCartButtonEvent();
    },
  };
  return self;
})();

export default ProductDetail_Tracking;
