import Cookies from 'js-cookie';

export const productWarning = (() => {
    let flyout = document.querySelector('.product-warning-flyout');
    if (!flyout)
        return;
    let content = flyout.querySelector('.content');
    let backdrop = document.querySelector('.product-warning-backdrop');
    let closeButton = flyout.querySelector('.close-btn');
    let container = flyout.querySelector('.content-container');

    let self = {
        setCookie: (cookieName) => {
            let duration = flyout.dataset.cookieDuration;
            let expiryDays = parseInt(duration);

            if (!duration || isNaN(expiryDays)) return;

            if (expiryDays < 1) {
                Cookies.set(cookieName, true);
            } else {
                let cookieExpire = new Date();
                cookieExpire.setDate(cookieExpire.getDate() + expiryDays);
                Cookies.set(cookieName, true, {
                    expires: cookieExpire,
                });
            }
        },
        init: () => {
            if (flyout) {
                let cookieName = `product-warning-${flyout.id}`;
                if (Cookies.get(cookieName))
                    return;

                const modalDelayInSeconds = isNaN(flyout.dataset.modalDelay) ? 0 : flyout.dataset.modalDelay;

                setTimeout(() => {
                    self.show();
                }, modalDelayInSeconds * 1000);

                self.setCookie(cookieName);

                backdrop.addEventListener('click', function () {
                    self.hide();
                });

                flyout.querySelector('.close-btn').addEventListener('click', function () {
                    self.hide();
                });

                if (content.scrollHeight > container.clientHeight) {
                    closeButton.classList.add('scroll-on');
                    container.classList.add('scroll-on');
                }
            }
        },

        show: () => {
            flyout.classList.add('wrapper-visible');
            backdrop.classList.add('active');
            document.querySelector('html').classList.add('no-scroll');
            document.querySelector('body').classList.add('no-scroll');
        },

        hide: () => {
            backdrop.classList.remove('active');
            flyout.classList.remove('wrapper-visible');
            document.querySelector('html').classList.remove('no-scroll');
            document.querySelector('body').classList.remove('no-scroll');
        },
    };
    return self;
})();