export const dynamicBundle = (() => {
    return {
      init: () => {
        const dynamicBundles = document.querySelectorAll('.dynamicBundle');
  
        dynamicBundles.forEach(bundle => {
          const bundleItems = bundle.querySelectorAll('.dynamicBundle__item');
          const bundleNameElement = bundle.querySelector('.bundleName');
          const initialActiveItem = bundle.querySelector('.dynamicBundle__item.isActive');
  
          if (initialActiveItem) 
            bundleNameElement.textContent = initialActiveItem.getAttribute('title');
         
          bundleItems.forEach(item => {
            item.addEventListener('mouseenter', function () {
              bundleItems.forEach(i => i.classList.remove('isActive'));
              item.classList.add('isActive');
              bundleNameElement.textContent = item.getAttribute('title');
            });
            item.addEventListener('mouseleave', function () {
                bundleItems.forEach(i => i.classList.remove('isActive'));
                if (initialActiveItem) {
                    initialActiveItem.classList.add('isActive');
                    bundleNameElement.textContent = initialActiveItem.getAttribute('title');
                  }
              });
          });
        });
      }
    }
  })();