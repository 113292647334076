export const getMercadoPaymentMessage = (baseUrl, apiKey, amount) => {
  fetch(`${baseUrl}?public_key=${apiKey}&payment_method_id=visa&amount=${amount}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.length > 0) {
        const installmentOptions = data[0].payer_costs;
        if (installmentOptions?.length > 0) {
          const selectedOption =
            installmentOptions.length >= 6 ? installmentOptions[5] : installmentOptions[installmentOptions.length - 1];
          document.querySelector('.mercado-messaging').innerHTML =
            selectedOption.recommended_message + " com <span class='fw-bold'>Mercado Pago</span>";
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
